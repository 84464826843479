import { createApp } from 'vue'
import { registerApp } from './global/index'
import 'normalize.css'
import './assets/css/index.less'
import App from './App.vue'
import router from './router'
import store from './store'
import { HyRequest } from './service'
import { setupStore } from './store'

const app = createApp(App)

import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
import 'element-plus/lib/theme-chalk/index.css'

// registerApp(app)
app.use(ElementPlus)
app.use(store)
setupStore()
app.use(router)
app.mount('#app')

// HyRequest.request({
//   url: '',
//   method: 'POST',
//   showLoading: false,
//   interceptors: {
//     requestInterceptor: (config) => {
//       console.log('单个请求拦截')
//       return config
//     },
//   },
// })
