import HYRequest from './request'
import { BASE_URL } from './request/config' //引入BASE_URL
import localCache from '@/utils/cache'
import { ElMessage } from 'element-plus'
const HyRequest = new HYRequest({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  interceptors: {
    requestInterceptor: (config: any) => {
      const token = localCache.getCache('token')
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      // console.log('请求成功地拦截')
      return config
    },
    requestInterceptorCatch: (err) => {
      // console.log('请求失败地拦截')
      return err
    },
    responseInterceptor: (res) => {
      if (res.data.success == true) {
        console.log('请求成功')
      } else if (res.data.success == false) {
        ElMessage({
          type: 'error',
          message: res.data.msg,
        })
      }
      return res.data.data
    },
    responseInterceptorCatch: (err) => {
      // console.log('响应失败地拦截')
      return err
    },
  },
})
const HYRequestProduct = new HYRequest({
  baseURL: BASE_URL + '/Product',
  interceptors: {
    requestInterceptor: (config: any) => {
      const token = localCache.getCache('token')
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      // console.log('请求成功地拦截')
      return config
    },
    requestInterceptorCatch: (err) => {
      // console.log('请求失败地拦截')
      return err
    },
    responseInterceptor: (res) => {
      // console.log('响应成功地拦截')
      if (res.data.code == 200) {
        console.log('请求成功')
      } else if (res.data.code == 500) {
        ElMessage({
          type: 'error',
          message: res.data.msg,
        })
      }
      return res.data.data
    },
    responseInterceptorCatch: (err) => {
      // console.log('响应失败地拦截')
      return err
    },
  },
})

const HyRequestWxLive = new HYRequest({
  baseURL: BASE_URL + '/WxLive',
  interceptors: {
    requestInterceptor: (config: any) => {
      const token = localCache.getCache('token')
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      // console.log('请求成功地拦截')
      return config
    },
    requestInterceptorCatch: (err) => {
      // console.log('请求失败地拦截')
      return err
    },
    responseInterceptor: (res) => {
      // console.log('响应成功地拦截')
      if (res.data.code == 200) {
        console.log('请求成功')
      } else if (res.data.code == 500) {
        ElMessage({
          type: 'error',
          message: res.data.msg,
        })
      }
      return res.data.data
    },
    responseInterceptorCatch: (err) => {
      // console.log('响应失败地拦截')
      return err
    },
  },
})

export { HyRequest, HYRequestProduct, HyRequestWxLive }
