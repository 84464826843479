import { Module } from 'vuex'
import { IRootState } from '../type'
import router from '@/router'
import {
  accountLoginRequest,
  RequestUserInfoById,
  requestUserMenus,
} from '@/service/login/login'
import localCache from '@/utils/cache'
import { mapMenusToRouters } from '@/utils/map-menus'

interface IloginState {
  token: string
  userInfo: any
  userMenus: any
}
const loginModule: Module<IloginState, IRootState> = {
  namespaced: true, //命名空间
  state: () => {
    return {
      token: '',
      userInfo: {},
      userMenus: [],
      // userMenus: [
      //   {
      //     icon: 'el-icon-video-camera-solid',
      //     id: 1,
      //     name: '直播间',
      //     sort: 1,
      //     type: 2,
      //     url: '/main/live',
      //   },
      //   {
      //     icon: 'el-icon-s-shop',
      //     id: 2,
      //     name: '商品库',
      //     sort: 1,
      //     type: 1,
      //     children: [
      //       {
      //         id: 21,
      //         name: '未入库',
      //         parentId: 1,
      //         sort: 100,
      //         type: 2,
      //         url: '/main/commodity/no-warehousing',
      //       },
      //       {
      //         id: 22,
      //         name: '已入库',
      //         parentId: 1,
      //         sort: 100,
      //         type: 2,
      //         url: '/main/commodity/warehousing',
      //       },
      //     ],
      //   },
      //   {
      //     icon: 'el-icon-s-custom',
      //     id: 3,
      //     name: '直播成员',
      //     sort: 1,
      //     type: 2,
      //     url: '/main/live-member',
      //   },
      // ],
    }
  },
  mutations: {
    changeUserMenus(state, userMenus: any) {
      state.userMenus = userMenus
      const routes = mapMenusToRouters(userMenus)
      routes.forEach((item) => {
        router.addRoute('main', item)
      })
    },
  },
  getters: {},
  actions: {
    async accountLoginAction({ commit }, payload: any) {
      // 3.请求用户菜单
      const userMenusResult = await requestUserMenus()
      console.log(userMenusResult)

      const userMenus = userMenusResult
      commit('changeUserMenus', userMenus)

      localCache.setCache('userMenus', userMenus)
      console.log(userMenus)
      // 4.跳转到首页
      router.push('/main')
    },
    // 5.获取本地缓存中的数据到vuex
    loadLocalLogin({ commit }) {
      const userMenus = localCache.getCache('userMenus')
      if (userMenus) {
        commit('changeUserMenus', userMenus)
      }
    },
  },
}
export default loginModule
