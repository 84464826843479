
import { defineComponent, nextTick, provide, ref } from 'vue'
import router from '@/router'
export default defineComponent({
  name: 'App',
  components: {},
  provide() {
    return {}
  },
  setup() {
    // 控制路由显示隐藏从而刷新页面
    let insRouterShow = ref(true)
    const reload: any = () => {
      insRouterShow.value = false
      nextTick(() => {
        // console.log('显示')
        insRouterShow.value = true
      })
    }
    provide('reload', reload)
    return { insRouterShow }
  },
})
