import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import localCache from '@/utils/cache'
const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/main',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/view/login/login.vue'),
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('@/view/main/main.vue'),
    children: [
      {
        path: '/main/live-create-room',
        name: 'LiveCreateRoom',
        component: () =>
          import('@/view/main/live-create-room/live-create-room.vue'),
      },
      {
        path: '/main/live-control',
        name: 'liveControl',
        component: () => import('@/view/main/live-control/live-control.vue'),
      },
    ],
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('@/view/not-found/not-found.vue'),
  },
]

const router = createRouter({
  routes,
  history: createWebHashHistory(),
})
router.beforeEach((to) => {
  if (to.path != '/login') {
    const userMenus = localCache.getCache('userMenus')

    if (!userMenus) {
      return '/login'
    }
  }
})

export default router
