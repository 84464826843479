import { createStore, Store, useStore as VuexStore } from 'vuex'
import login from './login/login'
import main from './login/login'
import { IRootState, IStoreType } from './type'

const store = createStore<IRootState>({
  state: () => {
    return {
      name: 'hello world',
      age: 15,
    }
  },
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    login,
    main,
  },
})
export function setupStore() {
  store.dispatch('login/loadLocalLogin')
}
// Vuex结合TS
export function useStore(): Store<IStoreType> {
  return VuexStore()
}
export default store
