// 开发环境 development
// 生产环境 production
// 测试环境 test
let BASE_URL = ''
let BASE_NAME = ''

if (process.env.NODE_ENV === 'development') {
  BASE_URL = '/apis'
  // BASE_URL = 'http://192.168.1.191:8082/api'
  BASE_NAME = '开发环境'
} else if (process.env.NODE_ENV === 'production') {
  BASE_URL = 'https://xy.supercmall.cn/api'
  BASE_NAME = '生产环境'
} else {
  BASE_URL = 'https://mm.supercmall.cn/api'
  BASE_NAME = '测试环境'
}
console.log(BASE_URL)

export { BASE_URL, BASE_NAME }
