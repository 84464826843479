/* eslint-disable @typescript-eslint/no-var-requires */
import { RouteRecordRaw } from 'vue-router'

export function mapMenusToRouters(userMenus: any): RouteRecordRaw[] {
  const routes: RouteRecordRaw[] = []
  // 1.先加载所有的routes
  const allRoutes: RouteRecordRaw[] = []
  // 方法
  const routeFiles = require.context('../router/main', true, /\.ts/)
  // console.log(routeFiles)
  routeFiles.keys().forEach((key) => {
    const route = require('../router/main' + key.split('.')[1]) //分割截取索引1之前的字符串
    allRoutes.push(route.default)
  })
  // console.log(allRoutes)
  // 2.根据菜单获取需要添加的routes
  // 递归设置动态添加路由
  const recurseGetRoute = (menus: any[]) => {
    for (const menu of menus) {
      if (menu.type === 2) {
        const routeNew = allRoutes.find((item) => {
          return item.path == menu.url
        })
        if (routeNew) routes.push(routeNew)
      } else {
        recurseGetRoute(menu.children)
      }
    }
  }
  recurseGetRoute(userMenus)
  return routes
}
