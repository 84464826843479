import { HyRequest } from '../index'
import { IAccount, IDataType, IloginResult } from './type'

// 枚举类型
enum LoginApi {
  AccountLogin = '/login',
  LoginUserInfo = '/users/', //用法 users/id
  UserMenus = '/WxLive/Login', //
}
// 请求登录账号密码
export function accountLoginRequest(account: IAccount) {
  return HyRequest.post<IDataType<IloginResult>>({
    url: LoginApi.AccountLogin,
    data: account,
  })
}
// 获取登录用户信息
export function RequestUserInfoById(id: number) {
  return HyRequest.get<IDataType>({
    url: LoginApi.LoginUserInfo + id,
  })
}
// 获取菜单列表
export function requestUserMenus() {
  return HyRequest.post<IDataType>({
    url: LoginApi.UserMenus,
  })
}
